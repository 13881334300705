/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  ButtonDelete,
  ButtonSearch,
  FilterAction,
  SelectDatePicker,
  SelectItemLarge,
  SelectItemMax,
  Wrap,
  WrapperLabel,
} from "./styled";
import { DatePicker, Form, Pagination, Select, Table } from "antd";
import { Notification } from "Components";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { deleteManagerSchedules, getDetailCurriculum, getListSchedules } from "Apis/admin";
import { formatDate } from "Utils/convertValue";
import LoadingModal from "Pages/LoadingModal";
import { useSelector, useDispatch } from "react-redux";
import actions from "Store/Actions";
import { ErrorField, ErrorMessage, TableContainer } from "Layouts/Admin/styled";
import moment from "moment";
import { STATUS_ACTIVE, textNoData } from "Utils/data-default";
import { ERROR_API_MESSAGE, RESPONSE_STATUS } from "Constants";
import dayjs from "dayjs";
import ModalDetailStudent from "./ModalDetailStudent";
import { useTranslation } from "react-i18next";
import ModalDelete from "./ModalDelete";
import 'dayjs/locale/ja'
import locale from 'antd/es/date-picker/locale/ja_JP'
const ManagerSchedules = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(50);
  const [loadding, setLoadding] = useState(false);
  const [listSchedules, setListSchedules] = useState([]);
  const [openDetailModal, setOpenDetaiModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [dataDetail, setDataDetail] = useState();
  const [loaddingPage, setLoaddingPage] = useState(false);
  const [dataSearch, setDataSearch] = useState({});
  const [resetPage, setResetPage] = useState(1);
  const [listDepartmentName, setListDepartmentName] = useState([]);
  const [listMajorName, setListMajorDepartmentName] = useState([]);
  const [selectionType] = useState('checkbox');
  const [idSelect, setIdSelect] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [lastPage, setLastPage] = useState()
  const [lengthPage, setLengthPage] = useState()
  const [t] = useTranslation("student");
  const {
    getListStudentId,
    getListStudentName,
    getListUniversityAdmin,
    getListFullUniversityInfomation,
  } = actions;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState({
    student_id: false,
    student_name: false,
    university_name: false,
    department_name: false,
    major_name: false,
  });
  const {
    dataStudentId,
    dataStudentName,
    dataUniversityAdmin,
    dataFullUniverSityInfomation,
  } = useSelector((state) => state.admin);
  useEffect(() => {
    !dataStudentId.length && dispatch(getListStudentId((action, res) => {}));
    !dataStudentName.length && dispatch(getListStudentName((action, res) => {}));
    !dataUniversityAdmin.length && dispatch(getListUniversityAdmin((action, res) => {}));
  }, []);
  useEffect(() => {
    async function fetchData() {
      setLoadding(true);
      let dataFilter = {};
      if (dataSearch) {
        for (let key in dataSearch) {
          if (
            dataSearch.hasOwnProperty(key) &&
            dataSearch[key] !== "" &&
            dataSearch[key] !== undefined
          ) {
            dataFilter[key] = dataSearch[key];
          }
        }
      }

      try {
        const data = await getListSchedules({
          page: currentPage !== null ? currentPage - 1 : 0,
          size: pageSize,
          dataSearch: dataFilter,
        });
        const result = data?.data;
        setTotal(result.total);
        setLastPage(result.page)
        setLengthPage(result.items.length)
        setListSchedules(
          result?.items.map((item, index) => ({ ...item, key: index }))
        );
        setLoadding(false);
      } catch (error) {
        setLoadding(false);
        Notification.error(
          ERROR_API_MESSAGE[error.message_code] ||
            ERROR_API_MESSAGE[error.code] ||
            error.message ||
            error.errors?.[0].msg
        );
      }
    }
    fetchData();
  }, [pageSize, currentPage, resetPage]);
  const handleShowDetail = async (data) => {
    setLoaddingPage(true);
    try {
      const res = await getDetailCurriculum({
        curriculumID: data.curriculumID,
      });
      const result = res.data.data;
      setDataDetail(result);
      setOpenDetaiModal(true);
      setLoaddingPage(false);
    } catch (error) {
      setLoaddingPage(false);
      Notification.error(error.errors?.[0].msg || "error");
    }
  };
  const columns = [
    {
      title: "連番",
      render: (text, record, index) => (
        <span className="id_record" onClick={() => handleShowDetail(record)}>
          {(currentPage - 1) * pageSize + index + 1}
        </span>
      ),
      key: "stt",
    },
    {
      title: t("studentID"),
      dataIndex: "studentID",
      key: "studentID",
    },
    {
      title: t("student_name"),
      dataIndex: "studentName",
      key: "studentName",
    },
    {
      title: t("university_name"),
      dataIndex: "universityName",
      key: "universityName",
    },
    {
      title: t("faculty_name"),
      dataIndex: "departmentName",
      key: "departmentName",
    },
    {
      title: t("department_major") ,
      dataIndex: "majorName",
      key: "majorName",
      className: "min-150",
    },
    {
      title: t("schedule_method"),
      dataIndex: "scheduleMethodName",
      key: "scheduleMethodName",
      className: "min-150",
    },
    {
      title: t("social_science"),
      dataIndex: "scienceType",
      key: "scienceType",
      className: "min-150",
    },
    {
      title: t("status_active") ,
      dataIndex: "is_activate",
      key: "is_activate",
      render: (value) => STATUS_ACTIVE[value]
    },
    {
      title: t("student_class_list"),
      dataIndex: "studentClass",
      key: "studentClass",
    },
    {
      title: t("student_school_list"),
      dataIndex: "studentSchool",
      key: "studentSchool",
    },
    {
      title: t('create_at'),
      dataIndex: "createdAt",
      key: "createdAt",
      className: "min-150",
      render: (created_at) => <span>{formatDate(created_at)}</span>,
    },
  ];

  const handlePaginationChange = (current, pageSize) => {
    setCurrentPage(current);
    setPageSize(pageSize);
    setIdSelect([])
    setSelectedRowKeys([])
  };

  const validationSchema = yup
    .object({
      student_id: yup.string(),
      student_name: yup.string(),
      university_name: yup.string(),
      department_name: yup.string(),
      major_name: yup.string(),
      create_from: yup
        .string()
        .test(
          "check-import-to",
          "開始日は終了日よりの前に日付を選択してください",
          function (value) {
            const fromDate = moment(value, "DD/MM/YYYY");
            const toDate = moment(this.parent.create_to, "DD/MM/YYYY");
            return (
              fromDate.isSameOrBefore(toDate) ||
              !this.parent.create_to ||
              !value
            );
          }
        ),
      create_to: yup.string(),
    })
    .required();

  const form = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = form;
  const onSubmit = (value) => {
    setDataSearch(value);
    setCurrentPage(1);
    setResetPage((pre) => pre + 1);
    setIdSelect([])
    setSelectedRowKeys([])
  };
  const handleSelectKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(onSubmit)();
    }
  };
  const handleSelectDateFrom = (date, dateString) => {
    trigger("create_from");
    trigger("create_to");
    setValue(
      "create_from",
      dateString ? dayjs(dateString).format("DD/MM/YYYY") : dateString
    );
  };
  const handleSelectDateTo = (date, dateString) => {
    trigger("create_from");
    trigger("create_to");
    setValue(
      "create_to",
      dateString ? dayjs(dateString).format("DD/MM/YYYY") : dateString
    );
  };
  const universityNameValue = watch("university_name");
  const departmentNameValue = watch("department_name");
  const majorNameValue = watch("major_name");

  const getDetailInformation = (universityName) => {
    if (universityName) {
      dispatch(
        getListFullUniversityInfomation(
          { universityName: universityName },
          (action, res) => {
            if (res.status === RESPONSE_STATUS.SUCCESS) {
              let keys = Object.keys(res.data[`${universityName}`]);
              const department = keys.map((faculty) => ({
                value: faculty,
                label: faculty,
              }));
              setListDepartmentName(department);
            }
          }
        )
      );
    }
  };
  const getListMajorInformation = (departmentName) => {
    if (departmentName) {
      let majorName = dataFullUniverSityInfomation?.[`${universityNameValue}`]?.[`${departmentName}`]

      const listMajor = majorName.map((major) => ({
        value: major,
        label: major,
      }));
      setListMajorDepartmentName(listMajor)
    }
  };
  useEffect(() => {
    getDetailInformation(universityNameValue);
  }, [universityNameValue]);

  useEffect(() => {
    getListMajorInformation(departmentNameValue);
  }, [departmentNameValue]);

  const handleSearchUniversity = async (e) => {
    setValue("university_name", e)
    setValue('department_name',undefined)
    setValue('major_name', undefined)
  }
  const handleSearchDepartment = async (e) => {
    setValue("department_name", e)
    setValue('major_name', undefined)

  }
  const rowSelection = {
    onChange: (selectedKeys, selectedRows ) => {
      const listId = selectedRows.map(item => item.curriculumID)
      setIdSelect(listId)
      setSelectedRowKeys(selectedKeys)
    },
    selectedRowKeys,
  };
  
  const handleDelete = async () => {
    try {
      const data = await deleteManagerSchedules({ curriculum_ids: idSelect });
      if (data.data.status === RESPONSE_STATUS.SUCCESS) {
        Notification.success("受講予定講座を削除しました。");
        setIdSelect([])
        setSelectedRowKeys([])
        
        if (lastPage === currentPage && lengthPage === selectedRowKeys.length) {
          setCurrentPage(1);
        }
        setResetPage((pre) => pre + 1);
      }
    } catch (error) {
      Notification.error(
        ERROR_API_MESSAGE[error.message_code] ||
          ERROR_API_MESSAGE[error.code] ||
          error.message ||
          error.errors?.[0].msg
      );
    }
    setOpenDeleteModal(false)

  }
  const handlPopupDelete = () => {
    if (idSelect.length > 0) {
      setOpenDeleteModal(true)
    }
  }
  return (
    <Wrap>
      <div className="seihoku__import"></div>
      <FormProvider {...form}>
        <form id="information-form" autoComplete="off">
          <FilterAction>
            <SelectItemLarge>
              <Form.Item label={<WrapperLabel>大学名</WrapperLabel>}>
                <Select
                  className="select_type"
                  options={dataUniversityAdmin}
                  name="university_name"
                  showSearch
                  allowClear
                  value={universityNameValue}
                  onChange={handleSearchUniversity}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        university_name: false,
                      }));
                    }
                    handleSelectKeyDown(e);
                  }}
                  open={isOpen.university_name}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      university_name: open,
                    }));
                  }}
                />
              </Form.Item>
            </SelectItemLarge>
            <SelectItemMax>
              <Form.Item label={<WrapperLabel>{t("faculty_name")}</WrapperLabel>}>
                <Select
                  className="select_type"
                  options={listDepartmentName}
                  name="department_name"
                  showSearch
                  allowClear
                  value={departmentNameValue}
                  onChange={handleSearchDepartment}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        department_name: false,
                      }));
                    }
                    handleSelectKeyDown(e);
                  }}
                  open={isOpen.department_name}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      department_name: open,
                    }));
                  }}
                  disabled={!universityNameValue}
                />
              </Form.Item>
            </SelectItemMax>
            <SelectItemMax>
              <Form.Item label={<WrapperLabel>{t("department_major")}</WrapperLabel>}>
                <Select
                  className="select_type"
                  options={listMajorName}
                  name="major_name"
                  showSearch
                  allowClear
                  value={majorNameValue}
                  onChange={(e) => setValue("major_name", e)}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        major_name: false,
                      }));
                    }
                    handleSelectKeyDown(e);
                  }}
                  open={isOpen.major_name}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      major_name: open,
                    }));
                  }}
                  disabled={!departmentNameValue || !universityNameValue}
                />
              </Form.Item>
            </SelectItemMax>
          </FilterAction>
          <FilterAction>
            <SelectItemLarge>
              <Form.Item label={<WrapperLabel>{t("studentID")}</WrapperLabel>}>
                <Select
                  className="select_type"
                  options={dataStudentId}
                  name="student_id"
                  showSearch
                  allowClear
                  onChange={(e) => setValue("student_id", e)}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        student_id: false,
                      }));
                    }
                    handleSelectKeyDown(e);
                  }}
                  open={isOpen.student_id}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      student_id: open,
                    }));
                  }}
                />
              </Form.Item>
            </SelectItemLarge>
            <SelectItemLarge>
              <Form.Item label={<WrapperLabel>{t("student_name")}</WrapperLabel>}>
                <Select
                  className="select_type"
                  options={dataStudentName}
                  name="student_name"
                  showSearch
                  allowClear
                  onChange={(e) => setValue("student_name", e)}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        student_name: false,
                      }));
                    }
                    handleSelectKeyDown(e);
                  }}
                  open={isOpen.student_name}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      student_name: open,
                    }));
                  }}
                />
              </Form.Item>
            </SelectItemLarge>
            <SelectDatePicker>
              <Form.Item
                label={<WrapperLabel>登録日から</WrapperLabel>}
                validateStatus={errors.create_from ? "error" : ""}
              >
                <DatePicker
                  locale={locale}
                  name="create_from"
                  allowClear={true}
                  onChange={handleSelectDateFrom}
                  inputReadOnly={true}
                  placeholder="日付を選択してください"
                  getPopupContainer={() =>
                    document.getElementById("dropdown-year")
                  }
                />
              </Form.Item>
              <ErrorField>
                <ErrorMessage>{errors?.create_from?.message}</ErrorMessage>
              </ErrorField>
            </SelectDatePicker>
            <SelectDatePicker>
              <Form.Item
                label={<WrapperLabel>登録日まで</WrapperLabel>}
                validateStatus={errors.create_from ? "error" : ""}
              >
                <DatePicker
                  locale={locale}
                  name="create_to"
                  allowClear={true}
                  onChange={handleSelectDateTo}
                  inputReadOnly={true}
                  placeholder="日付を選択してください"
                  getPopupContainer={() =>
                    document.getElementById("dropdown-year")
                  }
                />
              </Form.Item>
            </SelectDatePicker>
            <ButtonSearch onClick={handleSubmit(onSubmit)}>
              <SearchOutlined />
              検索
            </ButtonSearch>
          </FilterAction>
        </form>
      </FormProvider>
      <ButtonDelete onClick={handlPopupDelete}> <DeleteOutlined />削除</ButtonDelete>
      <TableContainer>
        <Table
          columns={columns}
          loading={loadding}
          dataSource={listSchedules}
          pagination={false}
          scroll={{ y: 440, x: true }}
          locale={{
            emptyText: textNoData,
          }}
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
        />
        {listSchedules && listSchedules?.length > 0 && (
          <Pagination
            style={{ marginTop: "16px", textAlign: "right" }}
            total={total}
            current={currentPage}
            onChange={handlePaginationChange}
            showSizeChanger={true}
            pageSize={pageSize}
            locale={{
              items_per_page: "/ ページ",
            }}
          />
        )}
      </TableContainer>
      {/* <ModalSeihokuDetail
          visibleModal={openDetailModal}
          onCancel={() => setOpenDetaiModal(false)}
          onOk={() => setOpenDetaiModal(false)}
          dataDetail={dataDetail}
        /> */}
      <ModalDetailStudent
        visibleModal={openDetailModal}
        onCancel={() => setOpenDetaiModal(false)}
        onOk={() => setOpenDetaiModal(false)}
        dataDetail={dataDetail}
      />
      <ModalDelete
        visibleModal={openDeleteModal}
        onCancel={() => setOpenDeleteModal(false)}
        onOk={handleDelete}
      />
      {loaddingPage && (
        <LoadingModal cancelEvent={() => setLoaddingPage(false)} />
      )}
    </Wrap>
  );
};
export default ManagerSchedules;
