import { Col } from 'antd'
import ModalComponent from 'Components/Modal'
import styled from 'styled-components'

export const ModalComponentWrapper= styled(ModalComponent)`
  .ant-list.ant-list-split {
    padding-top: 0;
  }
  .ant-tabs-nav{
    margin-bottom: 0;
  }
  .ant-tabs-nav-list {
    .ant-tabs-tab  {
      padding: 12px 14px;
      margin:0;
    }

    .ant-tabs-tab-active {
      background-color: #7D0006;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;

      .ant-tabs-tab-btn {
        color: #fff;
      }
    }
  }
  input{
    :disabled {
      color: #000;
    }
  }
  .table-content{
    width: 100%;
  }
  .table-detail{
    margin: 0;
  }
  .key-child,
  .list-action {
    line-height: 30px !important;
    span {
      align-items: center;
      line-height: 30px !important;
    }
  }
  .ant-list.ant-list-split {
    
    .ant-list-item {
      .list-action {
        @media only screen and (max-width: 920px) {
          padding-left: 5px;
          .course-item {
            padding-right: 5px; 
          }
        }
      }
      .name-courses {
        .greater-than-7 {
          min-height: 120px !important;
        }
        .greater-than-6 {
          min-height: 90px !important;
        }
        .greater-than-5 {
          min-height: 90px !important;
        }
        .greater-than-4, .greater-than-3 {
          min-height: 60px !important;
        }
        @media only screen and (max-width: 920px) {
          .greater-than-7 {
            min-height: 150px !important;
          }
          .greater-than-6 {
            min-height: 120px !important;
          }
          .greater-than-5 {
            min-height: 120px !important;
          }
          .greater-than-2{
            min-height: 60px !important;
          }
        }
        @media only screen and (max-width: 768px) {
          .greater-than-7 {
            min-height: 180px !important;
          }
          .greater-than-6 {
            min-height: 150px !important;
          }
          .greater-than-5 {
            min-height: 150px !important;
          }
          .greater-than-2,.greater-than-3, .greater-than-4{
            min-height: 90px !important;
          }
        }
      }
      .list-item-subject {
        .ant-spin-container {
          .ant-list-items {
            .ant-list-item {
              border-bottom: 1px solid #c9c9c9 !important;
              &:last-child {
                border-bottom: none !important;
              }
              .greater-than-7 {
                min-height: 120px !important;
              }
              .greater-than-6 {
                min-height: 90px !important;
              }
              .greater-than-5 {
                min-height: 90px !important;
              }
              .greater-than-4, .greater-than-3 {
                min-height: 60px !important;
              }
              @media only screen and (max-width: 920px) {
                .greater-than-7 {
                  min-height: 150px !important;
                }
                .greater-than-6 {
                  min-height: 120px !important;
                }
                .greater-than-5 {
                  min-height: 120px !important;
                }
                .greater-than-2{
                  min-height: 60px !important;
                }
              }
              @media only screen and (max-width: 768px) {
                .greater-than-7 {
                  min-height: 180px !important;
                }
                .greater-than-6 {
                  min-height: 150px !important;
                }
                .greater-than-5 {
                  min-height: 150px !important;
                }
                .greater-than-2, .greater-than-3, .greater-than-4{
                  min-height: 90px !important;
                }
              }
          }
        }
      }
    }
  }
}
`

export const Label = styled.div`
  font-size: 16px;
  color: #6d737a;
`
export const TitleTable = styled.div`
  font-size: 16px;
  color: #000;
  font-weight: bold;
  margin: 10px 0;
`
export const NoDataTable = styled.div`
  width: 100%;
  border: 1px solid #f0f0f0;
  height: 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color:rgba(0, 0, 0, 0.25);
`

export const ColWraper= styled(Col)`
  display: flex;
  justify-content: flex-end;
`

export const EndCol= styled(Col)`
  display: flex;
  justify-content: flex-end;
`

export const StartCol= styled(Col)`
  display: flex;
  justify-content: flex-start;
`

export const DateTimeTitle= styled.div`
  font-weight: 500;
	font-size: 1.3rem;
	line-height: 30px;
	color: #6D737A;
`

export const MidleDateTime= styled(Col)`
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
`

export const FailureBtn = styled.button`
  background: #7d0006;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #ffffff;
  font-family: "Public Sans";
  height: 45px;
  min-width: 13rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #7d0006;
`;