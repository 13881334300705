/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Input, Row, Table } from "antd";
import React from "react";
import {
  DateTimeTitle,
  Label,
  MidleDateTime,
  ModalComponentWrapper,
  NoDataTable,
  TitleTable,
} from "./styled";
import { useTranslation } from "react-i18next";
import {
  convertAutotickSubject,
  formatDateYYYYMMDD,
  valueCharacter,
} from "Utils/convertValue";
import { TableContainer } from "Layouts/Admin/styled";
import { isEmpty } from 'lodash';
import ListCourseChecked from "Pages/Home/ListCourseChecked";
import ListCourseCheckedOld from "Pages/Home/DetailScheduleds/ListCourseChecked/index";
import { useState } from "react";
import { useEffect } from "react";
const ModalDetailStudent = ({ visibleModal, onOk, onCancel, dataDetail }) => {
  const [t] = useTranslation("student");
  const [columnsCommonTest, setColumnsCommonTest] = useState([])
  const [columnsSecondaryInvidual, setColumnsSecondaryInvidual] = useState([])

  useEffect(() => {
    if (dataDetail && dataDetail?.score) {
      const keysSecondaryInvidual = Object.keys(dataDetail?.score['二次・個別']);
      const keysCommonTest = Object.keys(dataDetail?.score['共通テスト']);
  
      const columnsSecondary = keysSecondaryInvidual.map(key => {
        return {
          title: key,
          dataIndex: key,
          key: key
        };
      });
      const columnsCommon = keysCommonTest.map(key => {
        return {
          title: key,
          dataIndex: key,
          key: key
        };
      });
      setColumnsSecondaryInvidual(columnsSecondary)
      setColumnsCommonTest(columnsCommon)
    }

  },[dataDetail])
  const columnsCharacter = [
    {
      title: "プレッシャー耐性",
      dataIndex: "ストレス耐性",
      key: "ストレス耐性",
      render: (value) => valueCharacter?.[value] || "",
    },
    {
      title: "誉めによる意欲向上",
      dataIndex: "奮起度",
      key: "奮起度",
      render: (value) => valueCharacter?.[value] || "",
    },
    {
      title: "負荷の耐性",
      dataIndex: "誉め耐性",
      key: "誉め耐性",
      render: (value) => valueCharacter?.[value] || "",
    },
    {
      title: "上昇志向",
      dataIndex: "上昇志向",
      key: "上昇志向",
      className: "min-150",
      render: (value) => valueCharacter?.[value] || "",
    },
    {
      title: "自立性",
      dataIndex: "自立性",
      key: "自立性",
      render: (value) => valueCharacter?.[value] || "",
    },
  ];
  return (
    <>
      <ModalComponentWrapper
        open={visibleModal}
        title={"生徒情報詳細"}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Row gutter={[32]}>
          <Col span={24} lg={12}>
            <Label>{t("studentID")}</Label>
            <Input
              disabled={true}
              name="aspiration_id"
              type="text"
              value={dataDetail?.studentID}
            />
          </Col>
          <Col span={24} lg={12}>
            <Label>{t("student_name")}</Label>
            <Input
              disabled={true}
              name="aspiration_id"
              type="text"
              value={dataDetail?.aspirational?.studentName}
            />
          </Col>
        </Row>
        <Row gutter={[32]}>
          <Col span={24} lg={12}>
            <Label>{t("university_name")}</Label>
            <Input
              disabled={true}
              name="aspiration_id"
              type="text"
              value={dataDetail?.aspirational?.universityName}
            />
          </Col>
          <Col span={24} lg={12}>
            <Label>{t("faculty_name")}</Label>
            <Input
              disabled={true}
              name="aspiration_id"
              type="text"
              value={dataDetail?.aspirational?.departmentName}
            />
          </Col>
        </Row>
        <Row gutter={[32]}>
          <Col span={24} lg={12}>
            <Label>{t("department_major")}</Label>
            <Input
              disabled={true}
              name="aspiration_id"
              type="text"
              value={dataDetail?.aspirational?.majorName}
            />
          </Col>
          <Col span={24} lg={12}>
            <Label>{t("schedule_method")}</Label>
            <Input
              disabled={true}
              name="aspiration_id"
              type="text"
              value={dataDetail?.aspirational?.scheduleMethod}
            />
          </Col>
        </Row>
        <Row gutter={[32]}>
          <Col span={24} lg={12}>
            <Label>{t("social_science")}</Label>
            <Input
              disabled={true}
              name="aspiration_id"
              type="text"
              value={dataDetail?.scienceType}
            />
          </Col>
          <Col span={24} lg={12}>
            <DateTimeTitle span={24}>
              <span>{t("course_period")}</span>
            </DateTimeTitle>
            <Row gutter={[32]}>
              <Col span={11}>
                <Input
                  disabled={true}
                  name="aspiration_id"
                  type="text"
                  value={formatDateYYYYMMDD(dataDetail?.startTime)}
                />
              </Col>
              <MidleDateTime span={2}>~</MidleDateTime>
              <Col span={11}>
                <Input
                  disabled={true}
                  name="aspiration_id"
                  type="text"
                  value={formatDateYYYYMMDD(dataDetail?.endTime)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {dataDetail?.aspirational?.branchAspiration === undefined ||
        dataDetail?.aspirational?.branchAspiration === null ? (
          <></>
        ) : (
          <Row gutter={[32]}>
            <Col span={24} lg={12}>
              <Label>{t("branch_name")}</Label>
              <Input
                disabled={true}
                name="aspiration_id"
                type="text"
                value={dataDetail?.aspirational?.branchAspiration}
              />
            </Col>
          </Row>
        )}
        <Row>
          <TitleTable>適性検査</TitleTable>
          <div className="table-content">
            <TableContainer className="table-detail">
              <Table
                columns={columnsCharacter}
                dataSource={[dataDetail?.personality]}
                pagination={false}
                scroll={{ y: 440, x: true }}
              />
            </TableContainer>
          </div>
        </Row>
        <Row>
          <TitleTable>共通テスト</TitleTable>
          <div className="table-content">
            {isEmpty(dataDetail?.score?.['共通テスト'])
              ? <NoDataTable>
              {t("no_data")}
            </NoDataTable>
              : <TableContainer className="table-detail">
              <Table
                columns={columnsCommonTest}
                dataSource={
                  isEmpty(dataDetail?.score?.['共通テスト'])
                    ? []
                    : [dataDetail?.score?.['共通テスト']]
                }
                pagination={false}
                scroll={{ y: 440, x: true }}
              />
            </TableContainer>}
          </div>
        </Row>
        <Row>
          <TitleTable>二次・個別</TitleTable>
          <div className="table-content">
          {isEmpty(dataDetail?.score?.['二次・個別'])
              ? <NoDataTable>
              {t("no_data")}
            </NoDataTable>
              :  <TableContainer className="table-detail">
              <Table
                columns={columnsSecondaryInvidual}
                dataSource={
                  isEmpty(dataDetail?.score?.['二次・個別'])
                    ? []
                    : [dataDetail?.score?.['二次・個別']]
                }
                pagination={false}
                scroll={{ y: 440, x: true }}
              />
            </TableContainer>}
           
          </div>
        </Row>
        {/* contentSubjectIDSaved: Array.isArray(data.data.selectedBox['二次・個別']['英語']) ? data.data.selectedBox : {
          '共通テスト': convertAutotickSubject(data.data.selectedBox['共通テスト']),
          '二次・個別': convertAutotickSubject(data.data.selectedBox['二次・個別'])
        }, */}
        {dataDetail &&
          (
            dataDetail?.version <= process.env.REACT_APP_VERSION ? 
            <ListCourseCheckedOld
              dataSource={dataDetail?.selectedBox?.['共通テスト']}
              dataselectedContentPerson={dataDetail?.selectedBox['二次・個別']} />
            :
            <ListCourseChecked
            dataSource={ convertAutotickSubject(dataDetail?.selectedBox?.['共通テスト'])}
            dataselectedContentPerson={ convertAutotickSubject(dataDetail?.selectedBox?.['二次・個別'])} />
          )
        }
          
          
      </ModalComponentWrapper>
    </>
  );
};

export default ModalDetailStudent;
